/*-----------------------------------------------------------------------------

-	Revolution Slider 5.0 Default Style Settings -

Screen Stylesheet

version:   	5.4.5
date:      	15/05/17
author:		themepunch
email:     	info@themepunch.com
website:   	http://www.themepunch.com
-----------------------------------------------------------------------------*/

#debungcontrolls,
.debugtimeline {
    width: 100%;
    box-sizing: border-box
}

.rev_column,
.rev_column .tp-parallax-wrap,
.tp-svg-layer svg {
    vertical-align: top
}

#debungcontrolls {
    z-index: 100000;
    position: fixed;
    bottom: 0;
    height: auto;
    background: rgba(0, 0, 0, .6);
    padding: 10px
}

.debugtimeline {
    height: 10px;
    position: relative;
    margin-bottom: 3px;
    display: none;
    white-space: nowrap
}

.debugtimeline:hover {
    height: 15px
}

.the_timeline_tester {
    background: #e74c3c;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0
}

.rs-go-fullscreen {
    position: fixed!important;
    width: 100%!important;
    height: 100%!important;
    top: 0!important;
    left: 0!important;
    z-index: 9999999!important;
    background: #fff!important
}

.debugtimeline.tl_slide .the_timeline_tester {
    background: #f39c12
}

.debugtimeline.tl_frame .the_timeline_tester {
    background: #3498db
}

.debugtimline_txt {
    color: #fff;
    font-weight: 400;
    font-size: 7px;
    position: absolute;
    left: 10px;
    top: 0;
    white-space: nowrap;
    line-height: 10px
}

.rtl {
    direction: rtl
}

@font-face {
    font-family: revicons;
    src: url(../fonts/revicons/revicons.eot?5510888);
    src: url(../fonts/revicons/revicons.eot?5510888#iefix) format('embedded-opentype'), url(../fonts/revicons/revicons.woff?5510888) format('woff'), url(../fonts/revicons/revicons.ttf?5510888) format('truetype'), url(../fonts/revicons/revicons.svg?5510888#revicons) format('svg');
    font-weight: 400;
    font-style: normal
}

[class*=" revicon-"]:before,
[class^=revicon-]:before {
    font-family: revicons;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em
}

.revicon-search-1:before {
    content: '\e802'
}

.revicon-pencil-1:before {
    content: '\e831'
}

.revicon-picture-1:before {
    content: '\e803'
}

.revicon-cancel:before {
    content: '\e80a'
}

.revicon-info-circled:before {
    content: '\e80f'
}

.revicon-trash:before {
    content: '\e801'
}

.revicon-left-dir:before {
    content: '\e817'
}

.revicon-right-dir:before {
    content: '\e818'
}

.revicon-down-open:before {
    content: '\e83b'
}

.revicon-left-open:before {
    content: '\e819'
}

.revicon-right-open:before {
    content: '\e81a'
}

.revicon-angle-left:before {
    content: '\e820'
}

.revicon-angle-right:before {
    content: '\e81d'
}

.revicon-left-big:before {
    content: '\e81f'
}

.revicon-right-big:before {
    content: '\e81e'
}

.revicon-magic:before {
    content: '\e807'
}

.revicon-picture:before {
    content: '\e800'
}

.revicon-export:before {
    content: '\e80b'
}

.revicon-cog:before {
    content: '\e832'
}

.revicon-login:before {
    content: '\e833'
}

.revicon-logout:before {
    content: '\e834'
}

.revicon-video:before {
    content: '\e805'
}

.revicon-arrow-combo:before {
    content: '\e827'
}

.revicon-left-open-1:before {
    content: '\e82a'
}

.revicon-right-open-1:before {
    content: '\e82b'
}

.revicon-left-open-mini:before {
    content: '\e822'
}

.revicon-right-open-mini:before {
    content: '\e823'
}

.revicon-left-open-big:before {
    content: '\e824'
}

.revicon-right-open-big:before {
    content: '\e825'
}

.revicon-left:before {
    content: '\e836'
}

.revicon-right:before {
    content: '\e826'
}

.revicon-ccw:before {
    content: '\e808'
}

.revicon-arrows-ccw:before {
    content: '\e806'
}

.revicon-palette:before {
    content: '\e829'
}

.revicon-list-add:before {
    content: '\e80c'
}

.revicon-doc:before {
    content: '\e809'
}

.revicon-left-open-outline:before {
    content: '\e82e'
}

.revicon-left-open-2:before {
    content: '\e82c'
}

.revicon-right-open-outline:before {
    content: '\e82f'
}

.revicon-right-open-2:before {
    content: '\e82d'
}

.revicon-equalizer:before {
    content: '\e83a'
}

.revicon-layers-alt:before {
    content: '\e804'
}

.revicon-popup:before {
    content: '\e828'
}

.rev_slider_wrapper {
    position: relative;
    z-index: 0;
    width: 100%
}

.rev_slider {
    position: relative;
    overflow: visible
}

.entry-content .rev_slider a,
.rev_slider a {
    box-shadow: none
}

.tp-overflow-hidden {
    overflow: hidden!important
}

.group_ov_hidden {
    overflow: hidden
}

.rev_slider img,
.tp-simpleresponsive img {
    max-width: none!important;
    transition: none;
    margin: 0;
    padding: 0;
    border: none
}

.rev_slider .no-slides-text {
    font-weight: 700;
    text-align: center;
    padding-top: 80px
}

.rev_slider>ul,
.rev_slider>ul>li,
.rev_slider>ul>li:before,
.rev_slider_wrapper>ul,
.tp-revslider-mainul>li,
.tp-revslider-mainul>li:before,
.tp-simpleresponsive>ul,
.tp-simpleresponsive>ul>li,
.tp-simpleresponsive>ul>li:before {
    list-style: none!important;
    position: absolute;
    margin: 0!important;
    padding: 0!important;
    overflow-x: visible;
    overflow-y: visible;
    background-image: none;
    background-position: 0 0;
    text-indent: 0;
    top: 0;
    left: 0
}

.rev_slider>ul>li,
.rev_slider>ul>li:before,
.tp-revslider-mainul>li,
.tp-revslider-mainul>li:before,
.tp-simpleresponsive>ul>li,
.tp-simpleresponsive>ul>li:before {
    visibility: hidden
}

.tp-revslider-mainul,
.tp-revslider-slidesli {
    padding: 0!important;
    margin: 0!important;
    list-style: none!important
}

.fullscreen-container,
.fullwidthbanner-container {
    padding: 0;
    position: relative
}

.rev_slider li.tp-revslider-slidesli {
    position: absolute!important
}

.tp-caption .rs-untoggled-content {
    display: block
}

.tp-caption .rs-toggled-content {
    display: none
}

.rs-toggle-content-active.tp-caption .rs-toggled-content {
    display: block
}

.rs-toggle-content-active.tp-caption .rs-untoggled-content {
    display: none
}

.rev_slider .caption,
.rev_slider .tp-caption {
    position: relative;
    visibility: hidden;
    white-space: nowrap;
    display: block;
    -webkit-font-smoothing: antialiased!important;
    z-index: 1
}

.rev_slider .caption,
.rev_slider .tp-caption,
.tp-simpleresponsive img {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none
}

.rev_slider .tp-mask-wrap .tp-caption,
.rev_slider .tp-mask-wrap:last-child,
.wpb_text_column .rev_slider .tp-mask-wrap .tp-caption,
.wpb_text_column .rev_slider .tp-mask-wrap:last-child {
    margin-bottom: 0
}

.tp-svg-layer svg {
    width: 100%;
    height: 100%;
    position: relative
}

.tp-carousel-wrapper {
    cursor: url(openhand.cur), move
}

.tp-carousel-wrapper.dragged {
    cursor: url(closedhand.cur), move
}

.tp_inner_padding {
    box-sizing: border-box;
    max-height: none!important
}

.tp-caption.tp-layer-selectable {
    -moz-user-select: all;
    -khtml-user-select: all;
    -webkit-user-select: all;
    -o-user-select: all
}

.tp-caption.tp-hidden-caption,
.tp-forcenotvisible,
.tp-hide-revslider,
.tp-parallax-wrap.tp-hidden-caption {
    visibility: hidden!important;
    display: none!important
}

.rev_slider audio,
.rev_slider embed,
.rev_slider iframe,
.rev_slider object,
.rev_slider video {
    max-width: none!important
}

.tp-element-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0
}

.tp-blockmask,
.tp-blockmask_in,
.tp-blockmask_out {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 1000;
    transform: scaleX(0) scaleY(0)
}

.tp-parallax-wrap {
    transform-style: preserve-3d
}

.rev_row_zone {
    position: absolute;
    width: 100%;
    left: 0;
    box-sizing: border-box;
    min-height: 50px;
    font-size: 0
}

.rev_column_inner,
.rev_slider .tp-caption.rev_row {
    position: relative;
    width: 100%!important;
    box-sizing: border-box
}

.rev_row_zone_top {
    top: 0
}

.rev_row_zone_middle {
    top: 50%;
    transform: translateY(-50%)
}

.rev_row_zone_bottom {
    bottom: 0
}

.rev_slider .tp-caption.rev_row {
    display: table;
    table-layout: fixed;
    vertical-align: top;
    height: auto!important;
    font-size: 0
}

.rev_column {
    display: table-cell;
    position: relative;
    height: auto;
    box-sizing: border-box;
    font-size: 0
}

.rev_column_inner {
    display: block;
    height: auto!important;
    white-space: normal!important
}

.rev_column_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    box-sizing: border-box;
    background-clip: content-box;
    border: 0 solid transparent
}

.tp-caption .backcorner,
.tp-caption .backcornertop,
.tp-caption .frontcorner,
.tp-caption .frontcornertop {
    height: 0;
    top: 0;
    width: 0;
    position: absolute
}

.rev_column_inner .tp-loop-wrap,
.rev_column_inner .tp-mask-wrap,
.rev_column_inner .tp-parallax-wrap {
    text-align: inherit
}

.rev_column_inner .tp-mask-wrap {
    display: inline-block
}

.rev_column_inner .tp-parallax-wrap,
.rev_column_inner .tp-parallax-wrap .tp-loop-wrap,
.rev_column_inner .tp-parallax-wrap .tp-mask-wrap {
    position: relative!important;
    left: auto!important;
    top: auto!important;
    line-height: 0
}

.tp-video-play-button,
.tp-video-play-button i {
    line-height: 50px!important;
    vertical-align: top;
    text-align: center
}

.rev_column_inner .rev_layer_in_column,
.rev_column_inner .tp-parallax-wrap,
.rev_column_inner .tp-parallax-wrap .tp-loop-wrap,
.rev_column_inner .tp-parallax-wrap .tp-mask-wrap {
    vertical-align: top
}

.rev_break_columns {
    display: block!important
}

.rev_break_columns .tp-parallax-wrap.rev_column {
    display: block!important;
    width: 100%!important
}

.fullwidthbanner-container {
    overflow: hidden
}

.fullwidthbanner-container .fullwidthabanner {
    width: 100%;
    position: relative
}

.tp-static-layers {
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0
}

.tp-caption .frontcorner {
    border-left: 40px solid transparent;
    border-right: 0 solid transparent;
    border-top: 40px solid #00A8FF;
    left: -40px
}

.tp-caption .backcorner {
    border-left: 0 solid transparent;
    border-right: 40px solid transparent;
    border-bottom: 40px solid #00A8FF;
    right: 0
}

.tp-caption .frontcornertop {
    border-left: 40px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 40px solid #00A8FF;
    left: -40px
}

.tp-caption .backcornertop {
    border-left: 0 solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid #00A8FF;
    right: 0
}

.tp-layer-inner-rotation {
    position: relative!important
}

img.tp-slider-alternative-image {
    width: 100%;
    height: auto
}

.caption.fullscreenvideo,
.rs-background-video-layer,
.tp-caption.coverscreenvideo,
.tp-caption.fullscreenvideo {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute
}

.noFilterClass {
    filter: none!important
}

.rs-background-video-layer {
    visibility: hidden;
    z-index: 0
}

.caption.fullscreenvideo audio,
.caption.fullscreenvideo iframe,
.caption.fullscreenvideo video,
.tp-caption.fullscreenvideo iframe,
.tp-caption.fullscreenvideo iframe audio,
.tp-caption.fullscreenvideo iframe video {
    width: 100%!important;
    height: 100%!important;
    display: none
}

.fullcoveredvideo audio,
.fullscreenvideo audio .fullcoveredvideo video,
.fullscreenvideo video {
    background: #000
}

.fullcoveredvideo .tp-poster {
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.videoisplaying .html5vid .tp-poster {
    display: none
}

.tp-video-play-button {
    background: #000;
    background: rgba(0, 0, 0, .3);
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #FFF;
    margin-top: -25px;
    margin-left: -25px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    display: inline-block;
    z-index: 4;
    opacity: 0;
    transition: opacity .3s ease-out!important
}

.tp-audio-html5 .tp-video-play-button,
.tp-hiddenaudio {
    display: none!important
}

.tp-caption .html5vid {
    width: 100%!important;
    height: 100%!important
}

.tp-video-play-button i {
    width: 50px;
    height: 50px;
    display: inline-block;
    font-size: 40px!important
}

.rs-fullvideo-cover,
.tp-dottedoverlay,
.tp-shadowcover {
    height: 100%;
    top: 0;
    left: 0;
    position: absolute
}

.tp-caption:hover .tp-video-play-button {
    opacity: 1;
    display: block
}

.tp-caption .tp-revstop {
    display: none;
    border-left: 5px solid #fff!important;
    border-right: 5px solid #fff!important;
    margin-top: 15px!important;
    line-height: 20px!important;
    vertical-align: top;
    font-size: 25px!important
}

.tp-seek-bar,
.tp-video-button,
.tp-volume-bar {
    outline: 0;
    line-height: 12px;
    margin: 0;
    cursor: pointer
}

.videoisplaying .revicon-right-dir {
    display: none
}

.videoisplaying .tp-revstop {
    display: inline-block
}

.videoisplaying .tp-video-play-button {
    display: none
}

.fullcoveredvideo .tp-video-play-button {
    display: none!important
}

.fullscreenvideo .fullscreenvideo audio,
.fullscreenvideo .fullscreenvideo video {
    object-fit: contain!important
}

.fullscreenvideo .fullcoveredvideo audio,
.fullscreenvideo .fullcoveredvideo video {
    object-fit: cover!important
}

.tp-video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    opacity: 0;
    transition: opacity .3s;
    background-image: linear-gradient(to bottom, #000 13%, #323232 100%);
    display: table;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box
}

.rev-btn.rev-hiddenicon i,
.rev-btn.rev-withicon i {
    transition: all .2s ease-out!important;
    font-size: 15px
}

.tp-caption:hover .tp-video-controls {
    opacity: .9
}

.tp-video-button {
    background: rgba(0, 0, 0, .5);
    border: 0;
    border-radius: 3px;
    font-size: 12px;
    color: #fff;
    padding: 0
}

.tp-video-button:hover {
    cursor: pointer
}

.tp-video-button-wrap,
.tp-video-seek-bar-wrap,
.tp-video-vol-bar-wrap {
    padding: 0 5px;
    display: table-cell;
    vertical-align: middle
}

.tp-video-seek-bar-wrap {
    width: 80%
}

.tp-video-vol-bar-wrap {
    width: 20%
}

.tp-seek-bar,
.tp-volume-bar {
    width: 100%;
    padding: 0
}

.rs-fullvideo-cover {
    width: 100%;
    background: 0 0;
    z-index: 5
}

.disabled_lc .tp-video-play-button,
.rs-background-video-layer audio::-webkit-media-controls,
.rs-background-video-layer video::-webkit-media-controls,
.rs-background-video-layer video::-webkit-media-controls-start-playback-button {
    display: none!important
}

.tp-audio-html5 .tp-video-controls {
    opacity: 1!important;
    visibility: visible!important
}

.tp-dottedoverlay {
    background-repeat: repeat;
    width: 100%;
    z-index: 3
}

.tp-dottedoverlay.twoxtwo {
    background: url(../assets/gridtile.png)
}

.tp-dottedoverlay.twoxtwowhite {
    background: url(../assets/gridtile_white.png)
}

.tp-dottedoverlay.threexthree {
    background: url(../assets/gridtile_3x3.png)
}

.tp-dottedoverlay.threexthreewhite {
    background: url(../assets/gridtile_3x3_white.png)
}

.tp-shadowcover {
    width: 100%;
    background: #fff;
    z-index: -1
}

.tp-shadow1 {
    box-shadow: 0 10px 6px -6px rgba(0, 0, 0, .8)
}

.tp-shadow2:after,
.tp-shadow2:before,
.tp-shadow3:before,
.tp-shadow4:after {
    z-index: -2;
    position: absolute;
    content: "";
    bottom: 10px;
    left: 10px;
    width: 50%;
    top: 85%;
    max-width: 300px;
    background: 0 0;
    box-shadow: 0 15px 10px rgba(0, 0, 0, .8);
    transform: rotate(-3deg)
}

.tp-shadow2:after,
.tp-shadow4:after {
    transform: rotate(3deg);
    right: 10px;
    left: auto
}

.tp-shadow5 {
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .3), 0 0 40px rgba(0, 0, 0, .1) inset
}

.tp-shadow5:after,
.tp-shadow5:before {
    content: "";
    position: absolute;
    z-index: -2;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .6);
    top: 30%;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-radius: 100px/20px
}

.tp-button {
    padding: 6px 13px 5px;
    border-radius: 3px;
    height: 30px;
    cursor: pointer;
    color: #fff!important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .6)!important;
    font-size: 15px;
    line-height: 45px!important;
    font-family: arial, sans-serif;
    font-weight: 700;
    letter-spacing: -1px;
    text-decoration: none
}

.tp-button.big {
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .6);
    font-weight: 700;
    padding: 9px 20px;
    font-size: 19px;
    line-height: 57px!important
}

.purchase:hover,
.tp-button.big:hover,
.tp-button:hover {
    background-position: bottom, 15px 11px
}

.purchase.green,
.purchase:hover.green,
.tp-button.green,
.tp-button:hover.green {
    background-color: #21a117;
    box-shadow: 0 3px 0 0 #104d0b
}

.purchase.blue,
.purchase:hover.blue,
.tp-button.blue,
.tp-button:hover.blue {
    background-color: #1d78cb;
    box-shadow: 0 3px 0 0 #0f3e68
}

.purchase.red,
.purchase:hover.red,
.tp-button.red,
.tp-button:hover.red {
    background-color: #cb1d1d;
    box-shadow: 0 3px 0 0 #7c1212
}

.purchase.orange,
.purchase:hover.orange,
.tp-button.orange,
.tp-button:hover.orange {
    background-color: #f70;
    box-shadow: 0 3px 0 0 #a34c00
}

.purchase.darkgrey,
.purchase:hover.darkgrey,
.tp-button.darkgrey,
.tp-button.grey,
.tp-button:hover.darkgrey,
.tp-button:hover.grey {
    background-color: #555;
    box-shadow: 0 3px 0 0 #222
}

.purchase.lightgrey,
.purchase:hover.lightgrey,
.tp-button.lightgrey,
.tp-button:hover.lightgrey {
    background-color: #888;
    box-shadow: 0 3px 0 0 #555
}

.rev-btn,
.rev-btn:visited {
    outline: 0!important;
    box-shadow: none!important;
    text-decoration: none!important;
    line-height: 44px;
    font-size: 17px;
    font-weight: 500;
    padding: 12px 35px;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    cursor: pointer
}

.rev-btn.rev-uppercase,
.rev-btn.rev-uppercase:visited {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 900
}

.rev-btn.rev-withicon i {
    font-weight: 400;
    position: relative;
    top: 0;
    margin-left: 10px!important
}

.rev-btn.rev-hiddenicon i {
    font-weight: 400;
    position: relative;
    top: 0;
    opacity: 0;
    margin-left: 0!important;
    width: 0!important
}

.rev-btn.rev-hiddenicon:hover i {
    opacity: 1!important;
    margin-left: 10px!important;
    width: auto!important
}

.rev-btn.rev-medium,
.rev-btn.rev-medium:visited {
    line-height: 36px;
    font-size: 14px;
    padding: 10px 30px
}

.rev-btn.rev-medium.rev-hiddenicon i,
.rev-btn.rev-medium.rev-withicon i {
    font-size: 14px;
    top: 0
}

.rev-btn.rev-small,
.rev-btn.rev-small:visited {
    line-height: 28px;
    font-size: 12px;
    padding: 7px 20px
}

.rev-btn.rev-small.rev-hiddenicon i,
.rev-btn.rev-small.rev-withicon i {
    font-size: 12px;
    top: 0
}

.rev-maxround {
    border-radius: 30px
}

.rev-minround {
    border-radius: 3px
}

.rev-burger {
    position: relative;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    padding: 22px 0 0 14px;
    border-radius: 50%;
    border: 1px solid rgba(51, 51, 51, .25);
    -webkit-tap-highlight-color: transparent;
    cursor: pointer
}

.rev-burger span {
    display: block;
    width: 30px;
    height: 3px;
    background: #333;
    transition: .7s;
    pointer-events: none;
    transform-style: flat!important
}

.rev-burger.revb-white span,
.rev-burger.revb-whitenoborder span {
    background: #fff
}

.rev-burger span:nth-child(2) {
    margin: 3px 0
}

#dialog_addbutton .rev-burger:hover:first-child,
.open .rev-burger:first-child,
.open.rev-burger:first-child {
    transform: translateY(6px) rotate(-45deg)
}

#dialog_addbutton .rev-burger:hover:nth-child(2),
.open .rev-burger:nth-child(2),
.open.rev-burger:nth-child(2) {
    transform: rotate(-45deg);
    opacity: 0
}

#dialog_addbutton .rev-burger:hover:last-child,
.open .rev-burger:last-child,
.open.rev-burger:last-child {
    transform: translateY(-6px) rotate(-135deg)
}

.rev-burger.revb-white {
    border: 2px solid rgba(255, 255, 255, .2)
}

.rev-burger.revb-darknoborder,
.rev-burger.revb-whitenoborder {
    border: 0
}

.rev-burger.revb-darknoborder span {
    background: #333
}

.rev-burger.revb-whitefull {
    background: #fff;
    border: none
}

.rev-burger.revb-whitefull span {
    background: #333
}

.rev-burger.revb-darkfull {
    background: #333;
    border: none
}

.rev-burger.revb-darkfull span,
.rev-scroll-btn.revs-fullwhite {
    background: #fff
}

@keyframes rev-ani-mouse {
    0% {
        opacity: 1;
        top: 29%
    }
    15% {
        opacity: 1;
        top: 50%
    }
    50% {
        opacity: 0;
        top: 50%
    }
    100% {
        opacity: 0;
        top: 29%
    }
}

.rev-scroll-btn {
    display: inline-block;
    position: relative;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
    width: 35px;
    height: 55px;
    box-sizing: border-box;
    border: 3px solid #fff;
    border-radius: 23px
}

.rev-scroll-btn>* {
    display: inline-block;
    line-height: 18px;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 2px
}

.rev-scroll-btn>.active,
.rev-scroll-btn>:focus,
.rev-scroll-btn>:hover {
    color: #fff
}

.rev-scroll-btn>.active,
.rev-scroll-btn>:active,
.rev-scroll-btn>:focus,
.rev-scroll-btn>:hover {
    opacity: .8
}

.rev-scroll-btn.revs-fullwhite span {
    background: #333
}

.rev-scroll-btn.revs-fulldark {
    background: #333;
    border: none
}

.rev-scroll-btn.revs-fulldark span,
.tp-bullet {
    background: #fff
}

.rev-scroll-btn span {
    position: absolute;
    display: block;
    top: 29%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin: -4px 0 0 -4px;
    background: #fff;
    border-radius: 50%;
    animation: rev-ani-mouse 2.5s linear infinite
}

.rev-scroll-btn.revs-dark {
    border-color: #333
}

.rev-scroll-btn.revs-dark span {
    background: #333
}

.rev-control-btn {
    position: relative;
    display: inline-block;
    z-index: 5;
    color: #FFF;
    font-size: 20px;
    line-height: 60px;
    font-weight: 400;
    font-style: normal;
    font-family: Raleway;
    text-decoration: none;
    text-align: center;
    background-color: #000;
    border-radius: 50px;
    text-shadow: none;
    background-color: rgba(0, 0, 0, .5);
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    cursor: pointer
}

.rev-cbutton-dark-sr,
.rev-cbutton-light-sr {
    border-radius: 3px
}

.rev-cbutton-light,
.rev-cbutton-light-sr {
    color: #333;
    background-color: rgba(255, 255, 255, .75)
}

.rev-sbutton {
    line-height: 37px;
    width: 37px;
    height: 37px
}

.rev-sbutton-blue {
    background-color: #3B5998
}

.rev-sbutton-lightblue {
    background-color: #00A0D1
}

.rev-sbutton-red {
    background-color: #DD4B39
}

.tp-bannertimer {
    visibility: hidden;
    width: 100%;
    height: 5px;
    background: #000;
    background: rgba(0, 0, 0, .15);
    position: absolute;
    z-index: 200;
    top: 0
}

.tp-bannertimer.tp-bottom {
    top: auto;
    bottom: 0!important;
    height: 5px
}

.tp-caption img {
    background: 0 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
    zoom: 1
}

.caption.slidelink a div,
.tp-caption.slidelink a div {
    width: 3000px;
    height: 1500px;
    background: url(../img/slider/coloredbg.png)
}

.tp-caption.slidelink a span {
    background: url(../img/slider/coloredbg.png)
}

.tp-loader.spinner0,
.tp-loader.spinner5 {
    background-image: url(../img/slider/loader.gif);
    background-repeat: no-repeat
}

.tp-shape {
    width: 100%;
    height: 100%
}

.tp-caption .rs-starring {
    display: inline-block
}

.tp-caption .rs-starring .star-rating {
    float: none;
    display: inline-block;
    vertical-align: top;
    color: #FFC321!important
}

.tp-caption .rs-starring .star-rating,
.tp-caption .rs-starring-page .star-rating {
    position: relative;
    height: 1em;
    width: 5.4em;
    font-family: star;
    font-size: 1em!important
}

.tp-loader.spinner0,
.tp-loader.spinner1 {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    animation: tp-rotateplane 1.2s infinite ease-in-out;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15)
}

.tp-caption .rs-starring .star-rating:before,
.tp-caption .rs-starring-page .star-rating:before {
    content: "\73\73\73\73\73";
    color: #E0DADF;
    float: left;
    top: 0;
    left: 0;
    position: absolute
}

.tp-caption .rs-starring .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
    font-size: 1em!important
}

.tp-caption .rs-starring .star-rating span:before {
    content: "\53\53\53\53\53";
    top: 0;
    position: absolute;
    left: 0
}

.tp-loader {
    top: 50%;
    left: 50%;
    z-index: 10000;
    position: absolute
}

.tp-loader.spinner0 {
    background-position: center center
}

.tp-loader.spinner5 {
    background-position: 10px 10px;
    background-color: #fff;
    margin: -22px;
    width: 44px;
    height: 44px;
    border-radius: 3px
}

@keyframes tp-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0) rotateY(0)
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0)
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
    }
}

.tp-loader.spinner2 {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    background-color: red;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    border-radius: 100%;
    animation: tp-scaleout 1s infinite ease-in-out
}

@keyframes tp-scaleout {
    0% {
        transform: scale(0)
    }
    100% {
        transform: scale(1);
        opacity: 0
    }
}

.tp-loader.spinner3 {
    margin: -9px 0 0 -35px;
    width: 70px;
    text-align: center
}

.tp-loader.spinner3 .bounce1,
.tp-loader.spinner3 .bounce2,
.tp-loader.spinner3 .bounce3 {
    width: 18px;
    height: 18px;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    border-radius: 100%;
    display: inline-block;
    animation: tp-bouncedelay 1.4s infinite ease-in-out;
    animation-fill-mode: both
}

.tp-loader.spinner3 .bounce1 {
    animation-delay: -.32s
}

.tp-loader.spinner3 .bounce2 {
    animation-delay: -.16s
}

@keyframes tp-bouncedelay {
    0%,
    100%,
    80% {
        transform: scale(0)
    }
    40% {
        transform: scale(1)
    }
}

.tp-loader.spinner4 {
    margin: -20px 0 0 -20px;
    width: 40px;
    height: 40px;
    text-align: center;
    animation: tp-rotate 2s infinite linear
}

.tp-loader.spinner4 .dot1,
.tp-loader.spinner4 .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #fff;
    border-radius: 100%;
    animation: tp-bounce 2s infinite ease-in-out;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15)
}

.tp-loader.spinner4 .dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s
}

@keyframes tp-rotate {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes tp-bounce {
    0%,
    100% {
        transform: scale(0)
    }
    50% {
        transform: scale(1)
    }
}

.tp-bullets.navbar,
.tp-tabs.navbar,
.tp-thumbs.navbar {
    border: none;
    min-height: 0;
    margin: 0;
    border-radius: 0
}

.tp-bullets,
.tp-tabs,
.tp-thumbs {
    position: absolute;
    display: block;
    z-index: 1000;
    top: 0;
    left: 0
}

.tp-tab,
.tp-thumb {
    cursor: pointer;
    position: absolute;
    opacity: .5;
    box-sizing: border-box
}

.tp-arr-imgholder,
.tp-tab-image,
.tp-thumb-image,
.tp-videoposter {
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.tp-tab.selected,
.tp-tab:hover,
.tp-thumb.selected,
.tp-thumb:hover {
    opacity: 1
}

.tp-tab-mask,
.tp-thumb-mask {
    box-sizing: border-box!important
}

.tp-tabs,
.tp-thumbs {
    box-sizing: content-box!important
}

.tp-bullet {
    width: 15px;
    height: 15px;
    position: absolute;
    background: rgba(255, 255, 255, .3);
    cursor: pointer
}

.tp-bullet.selected,
.tp-bullet:hover {
    background: #fff
}

.tparrows {
    cursor: pointer;
    background: #000;
    background: rgba(0, 0, 0, .5);
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000
}

.tparrows:hover {
    background: #000
}

.tparrows:before {
    font-family: revicons;
    font-size: 15px;
    color: #fff;
    display: block;
    line-height: 40px;
    text-align: center
}

.hginfo,
.hglayerinfo {
    font-size: 12px;
    font-weight: 600
}

.tparrows.tp-leftarrow:before {
    content: '\e824'
}

.tparrows.tp-rightarrow:before {
    content: '\e825'
}

body.rtl .tp-kbimg {
    left: 0!important
}

.dddwrappershadow {
    box-shadow: 0 45px 100px rgba(0, 0, 0, .4)
}

.hglayerinfo {
    position: fixed;
    bottom: 0;
    left: 0;
    color: #FFF;
    line-height: 20px;
    background: rgba(0, 0, 0, .75);
    padding: 5px 10px;
    z-index: 2000;
    white-space: normal
}

.helpgrid,
.hginfo {
    position: absolute
}

.hginfo {
    top: -2px;
    left: -2px;
    color: #e74c3c;
    background: #000;
    padding: 2px 5px
}

.indebugmode .tp-caption:hover {
    border: 1px dashed #c0392b!important
}

.helpgrid {
    border: 2px dashed #c0392b;
    top: 0;
    left: 0;
    z-index: 0
}

#revsliderlogloglog {
    padding: 15px;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 150px;
    background: rgba(0, 0, 0, .7);
    z-index: 100000;
    font-size: 10px;
    overflow: scroll
}

.aden {
    filter: hue-rotate(-20deg) contrast(.9) saturate(.85) brightness(1.2)
}

.aden::after {
    background: linear-gradient(to right, rgba(66, 10, 14, .2), transparent);
    mix-blend-mode: darken
}

.perpetua::after,
.reyes::after {
    mix-blend-mode: soft-light;
    opacity: .5
}

.inkwell {
    filter: sepia(.3) contrast(1.1) brightness(1.1) grayscale(1)
}

.perpetua::after {
    background: linear-gradient(to bottom, #005b9a, #e6c13d)
}

.reyes {
    filter: sepia(.22) brightness(1.1) contrast(.85) saturate(.75)
}

.reyes::after {
    background: #efcdad
}

.gingham {
    filter: brightness(1.05) hue-rotate(-10deg)
}

.gingham::after {
    background: linear-gradient(to right, rgba(66, 10, 14, .2), transparent);
    mix-blend-mode: darken
}

.toaster {
    filter: contrast(1.5) brightness(.9)
}

.toaster::after {
    background: radial-gradient(circle, #804e0f, #3b003b);
    mix-blend-mode: screen
}

.walden {
    filter: brightness(1.1) hue-rotate(-10deg) sepia(.3) saturate(1.6)
}

.walden::after {
    background: #04c;
    mix-blend-mode: screen;
    opacity: .3
}

.hudson {
    filter: brightness(1.2) contrast(.9) saturate(1.1)
}

.hudson::after {
    background: radial-gradient(circle, #a6b1ff 50%, #342134);
    mix-blend-mode: multiply;
    opacity: .5
}

.earlybird {
    filter: contrast(.9) sepia(.2)
}

.earlybird::after {
    background: radial-gradient(circle, #d0ba8e 20%, #360309 85%, #1d0210 100%);
    mix-blend-mode: overlay
}

.mayfair {
    filter: contrast(1.1) saturate(1.1)
}

.mayfair::after {
    background: radial-gradient(circle at 40% 40%, rgba(255, 255, 255, .8), rgba(255, 200, 200, .6), #111 60%);
    mix-blend-mode: overlay;
    opacity: .4
}

.lofi {
    filter: saturate(1.1) contrast(1.5)
}

.lofi::after {
    background: radial-gradient(circle, transparent 70%, #222 150%);
    mix-blend-mode: multiply
}

._1977 {
    filter: contrast(1.1) brightness(1.1) saturate(1.3)
}

._1977:after {
    background: rgba(243, 106, 188, .3);
    mix-blend-mode: screen
}

.brooklyn {
    filter: contrast(.9) brightness(1.1)
}

.brooklyn::after {
    background: radial-gradient(circle, rgba(168, 223, 193, .4) 70%, #c4b7c8);
    mix-blend-mode: overlay
}

.xpro2 {
    filter: sepia(.3)
}

.xpro2::after {
    background: radial-gradient(circle, #e6e7e0 40%, rgba(43, 42, 161, .6) 110%);
    mix-blend-mode: color-burn
}

.nashville {
    filter: sepia(.2) contrast(1.2) brightness(1.05) saturate(1.2)
}

.nashville::after {
    background: rgba(0, 70, 150, .4);
    mix-blend-mode: lighten
}

.nashville::before {
    background: rgba(247, 176, 153, .56);
    mix-blend-mode: darken
}

.lark {
    filter: contrast(.9)
}

.lark::after {
    background: rgba(242, 242, 242, .8);
    mix-blend-mode: darken
}

.lark::before {
    background: #22253f;
    mix-blend-mode: color-dodge
}

.moon {
    filter: grayscale(1) contrast(1.1) brightness(1.1)
}

.moon::before {
    background: #a0a0a0;
    mix-blend-mode: soft-light
}

.moon::after {
    background: #383838;
    mix-blend-mode: lighten
}

.clarendon {
    filter: contrast(1.2) saturate(1.35)
}

.clarendon:before {
    background: rgba(127, 187, 227, .2);
    mix-blend-mode: overlay
}

.willow {
    filter: grayscale(.5) contrast(.95) brightness(.9)
}

.willow::before {
    background-color: radial-gradient(40%, circle, #d4a9af 55%, #000 150%);
    mix-blend-mode: overlay
}

.willow::after {
    background-color: #d8cdcb;
    mix-blend-mode: color
}

.rise {
    filter: brightness(1.05) sepia(.2) contrast(.9) saturate(.9)
}

.rise::after {
    background: radial-gradient(circle, rgba(232, 197, 152, .8), transparent 90%);
    mix-blend-mode: overlay;
    opacity: .6
}

.rise::before {
    background: radial-gradient(circle, rgba(236, 205, 169, .15) 55%, rgba(50, 30, 7, .4));
    mix-blend-mode: multiply
}

._1977:after,
._1977:before,
.aden:after,
.aden:before,
.brooklyn:after,
.brooklyn:before,
.clarendon:after,
.clarendon:before,
.earlybird:after,
.earlybird:before,
.gingham:after,
.gingham:before,
.hudson:after,
.hudson:before,
.inkwell:after,
.inkwell:before,
.lark:after,
.lark:before,
.lofi:after,
.lofi:before,
.mayfair:after,
.mayfair:before,
.moon:after,
.moon:before,
.nashville:after,
.nashville:before,
.perpetua:after,
.perpetua:before,
.reyes:after,
.reyes:before,
.rise:after,
.rise:before,
.slumber:after,
.slumber:before,
.toaster:after,
.toaster:before,
.walden:after,
.walden:before,
.willow:after,
.willow:before,
.xpro2:after,
.xpro2:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none
}

._1977,
.aden,
.brooklyn,
.clarendon,
.earlybird,
.gingham,
.hudson,
.inkwell,
.lark,
.lofi,
.mayfair,
.moon,
.nashville,
.perpetua,
.reyes,
.rise,
.slumber,
.toaster,
.walden,
.willow,
.xpro2 {
    position: relative
}

._1977 img,
.aden img,
.brooklyn img,
.clarendon img,
.earlybird img,
.gingham img,
.hudson img,
.inkwell img,
.lark img,
.lofi img,
.mayfair img,
.moon img,
.nashville img,
.perpetua img,
.reyes img,
.rise img,
.slumber img,
.toaster img,
.walden img,
.willow img,
.xpro2 img {
    width: 100%;
    z-index: 1
}

._1977:before,
.aden:before,
.brooklyn:before,
.clarendon:before,
.earlybird:before,
.gingham:before,
.hudson:before,
.inkwell:before,
.lark:before,
.lofi:before,
.mayfair:before,
.moon:before,
.nashville:before,
.perpetua:before,
.reyes:before,
.rise:before,
.slumber:before,
.toaster:before,
.walden:before,
.willow:before,
.xpro2:before {
    z-index: 2
}

._1977:after,
.aden:after,
.brooklyn:after,
.clarendon:after,
.earlybird:after,
.gingham:after,
.hudson:after,
.inkwell:after,
.lark:after,
.lofi:after,
.mayfair:after,
.moon:after,
.nashville:after,
.perpetua:after,
.reyes:after,
.rise:after,
.slumber:after,
.toaster:after,
.walden:after,
.willow:after,
.xpro2:after {
    z-index: 3
}

.slumber {
    filter: saturate(.66) brightness(1.05)
}

.slumber::after {
    background: rgba(125, 105, 24, .5);
    mix-blend-mode: soft-light
}

.slumber::before {
    background: rgba(69, 41, 12, .4);
    mix-blend-mode: lighten
}

.tp-kbimg-wrap:after,
.tp-kbimg-wrap:before {
    height: 500%;
    width: 500%
}

#rev_slider_1_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_1_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_1_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(255, 255, 255, 1);
    opacity: 1
}

#rev_slider_1_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_1_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(255, 255, 255, 1)
}

.tp-caption.LA_Black_btn,
.LA_Black_btn {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: #282828;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 0 0 0 0px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LA_Black_btn:hover,
.LA_Black_btn:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #cf987e;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 0 0 0 0px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}

.tp-caption.LA_White_btn,
.LA_White_btn {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: #ffffff;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 0 0 0 0px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LA_White_btn:hover,
.LA_White_btn:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #cf987e;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 0 0 0 0px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}
#rev_slider_2_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_2_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_2_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(255, 255, 255, 1);
    opacity: 1
}

#rev_slider_2_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_2_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(255, 255, 255, 1)
}



/*Home 05 CSS*/

.tp-caption.LA_White_btn,
.LA_White_btn {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: #ffffff;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 0 0 0 0px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LA_White_btn:hover,
.LA_White_btn:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #cf987e;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 0 0 0 0px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}

#rev_slider_3_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_3_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_3_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(255, 255, 255, 1);
    opacity: 1
}

#rev_slider_3_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_3_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(255, 255, 255, 1)
}


/*Home 07*/

.tp-caption.LA_Black_btn,
.LA_Black_btn {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: #282828;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 0 0 0 0px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LA_Black_btn:hover,
.LA_Black_btn:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #cf987e;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 0 0 0 0px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}

#rev_slider_4_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_4_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(40, 40, 40, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_4_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(40, 40, 40, 1);
    opacity: 1
}

#rev_slider_4_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(40, 40, 40, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_4_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(40, 40, 40, 1)
}

/*Home 09*/

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}

/*Home 11*/

.tp-caption.LA_White_btn,
.LA_White_btn {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: #ffffff;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 0 0 0 0px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LA_White_btn:hover,
.LA_White_btn:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #cf987e;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 0 0 0 0px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}
#rev_slider_6_1 .larev-style .tp-bullet {
    border-radius: 50%;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
    -webkit-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    background: transparent;
    width: 15px;
    height: 15px
}

#rev_slider_6_1 .larev-style .tp-bullet.selected,
#rev_slider_6_1 .larev-style .tp-bullet:hover {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);
    border: none;
    border-radius: 50%;
    background: transparent
}

#rev_slider_6_1 .larev-style .tp-bullet-inner {
    -webkit-transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
    transition: background-color 0.3s ease, transform 0.3s ease;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    border-radius: 50%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.3);
    text-indent: -999em;
    cursor: pointer;
    position: absolute
}

#rev_slider_6_1 .larev-style .tp-bullet.selected .tp-bullet-inner,
#rev_slider_6_1 .larev-style .tp-bullet:hover .tp-bullet-inner {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    background-color: rgb(0, 0, 0)
}

/*Home 12*/

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}
#rev_slider_7_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_7_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(40, 40, 40, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_7_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(40, 40, 40, 1);
    opacity: 1
}

#rev_slider_7_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(40, 40, 40, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_7_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(40, 40, 40, 1)
}

/*Home 13*/

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}
#rev_slider_8_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_8_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(40, 40, 40, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_8_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(40, 40, 40, 1);
    opacity: 1
}

#rev_slider_8_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(40, 40, 40, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_8_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(40, 40, 40, 1)
}

/*Home 14*/

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}

#rev_slider_9_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_9_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(40, 40, 40, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_9_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(40, 40, 40, 1);
    opacity: 1
}

#rev_slider_9_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(40, 40, 40, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_9_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(40, 40, 40, 1)
}

/*Home 15*/

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}

/*Home 17*/

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}
#rev_slider_11_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_11_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(40, 40, 40, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_11_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(40, 40, 40, 1);
    opacity: 1
}

#rev_slider_11_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(40, 40, 40, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_11_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(40, 40, 40, 1)
}

/*Home 17 - Product*/

#rev_slider_12_1 .ares-custom.tp-bullets {}

#rev_slider_12_1 .ares-custom.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    box-sizing: content-box
}

#rev_slider_12_1 .ares-custom .tp-bullet {
    width: 13px;
    height: 13px;
    position: absolute;
    background: rgba(216, 216, 216, 1);
    border-radius: 50%;
    cursor: pointer;
    box-sizing: content-box
}

#rev_slider_12_1 .ares-custom .tp-bullet:hover,
#rev_slider_12_1 .ares-custom .tp-bullet.selected {
    background: rgba(58, 58, 58, 1)
}

#rev_slider_12_1 .ares-custom .tp-bullet-title {
    position: absolute;
    color: 136, 136, 136;
    font-size: 12px;
    padding: 0px 10px;
    font-weight: 600;
    right: 27px;
    top: -4px;
    background: rgba(255, 255, 255, 0.75);
    visibility: hidden;
    transform: translatex(-20px);
    -webkit-transform: translatex(-20px);
    transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
    line-height: 20px;
    white-space: nowrap
}

#rev_slider_12_1 .ares-custom .tp-bullet-title:after {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
    content: " ";
    position: absolute;
    right: -10px;
    top: 0px
}

#rev_slider_12_1 .ares-custom .tp-bullet:hover .tp-bullet-title {
    visibility: visible;
    transform: translatex(0px);
    -webkit-transform: translatex(0px)
}

#rev_slider_12_1 .ares-custom .tp-bullet.selected:hover .tp-bullet-title {
    background: rgba(58, 58, 58, 1)
}

#rev_slider_12_1 .ares-custom .tp-bullet.selected:hover .tp-bullet-title:after {
    border-color: transparent transparent transparent rgba(58, 58, 58, 1)
}

#rev_slider_12_1 .ares-custom.tp-bullets:hover .tp-bullet-title {
    visibility: hidden
}

#rev_slider_12_1 .ares-custom.tp-bullets:hover .tp-bullet:hover .tp-bullet-title {
    visibility: visible;
    transform: translatex(0px) translatey(0px);
    -webkit-transform: translatex(0px) translatey(0px)
}

#rev_slider_12_1 .ares-custom.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title {
    right: auto;
    left: 27px;
    transform: translatex(20px);
    -webkit-transform: translatex(20px)
}

#rev_slider_12_1 .ares-custom.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title:after {
    border-width: 10px 10px 10px 0 !important;
    border-color: transparent rgba(255, 255, 255, 0.75) transparent transparent;
    right: auto !important;
    left: -10px !important
}

#rev_slider_12_1 .ares-custom.nav-dir-vertical.nav-pos-hor-left .tp-bullet.selected:hover .tp-bullet-title:after {
    border-color: transparent rgba(58, 58, 58, 1) transparent transparent !important
}

#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-center .tp-bullet-title,
#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-bottom .tp-bullet-title {
    top: -35px;
    left: 50%;
    right: auto;
    transform: translatex(-50%) translatey(-10px);
    -webkit-transform: translatex(-50%) translatey(-10px)
}

#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-center .tp-bullet-title:after,
#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-bottom .tp-bullet-title:after {
    border-width: 10px 10px 0px 10px;
    border-color: rgba(255, 255, 255, 0.75) transparent transparent transparent;
    right: auto;
    left: 50%;
    margin-left: -10px;
    top: auto;
    bottom: -10px
}

#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-center .tp-bullet.selected:hover .tp-bullet-title:after,
#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-bottom .tp-bullet.selected:hover .tp-bullet-title:after {
    border-color: rgba(58, 58, 58, 1) transparent transparent transparent
}

#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-center .tp-bullet:hover .tp-bullet-title,
#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-bottom .tp-bullet:hover .tp-bullet-title {
    transform: translatex(-50%) translatey(0px);
    -webkit-transform: translatex(-50%) translatey(0px)
}

#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title {
    top: 25px;
    left: 50%;
    right: auto;
    transform: translatex(-50%) translatey(10px);
    -webkit-transform: translatex(-50%) translatey(10px)
}

#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title:after {
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent rgba(255, 255, 255, 0.75) transparent;
    right: auto;
    left: 50%;
    margin-left: -10px;
    bottom: auto;
    top: -10px
}

#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-top .tp-bullet.selected:hover .tp-bullet-title:after {
    border-color: transparent transparent rgba(58, 58, 58, 1) transparent
}

#rev_slider_12_1 .ares-custom.nav-dir-horizontal.nav-pos-ver-top .tp-bullet:hover .tp-bullet-title {
    transform: translatex(-50%) translatey(0px);
    -webkit-transform: translatex(-50%) translatey(0px)
}





/*Home 03*/

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}

#rev_slider_16_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_16_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_16_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(0, 0, 0, 1);
    opacity: 1
}

#rev_slider_16_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_16_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(0, 0, 0, 1)
}

/*Home 04*/

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}

.tp-parallax-wrap.tp-shape-fullwidth .tp-shapewrapper {
    min-width: 50vw !important
}
#rev_slider_17_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_17_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_17_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(0, 0, 0, 1);
    opacity: 1
}

#rev_slider_17_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_17_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(0, 0, 0, 1)
}

/*Home 06*/

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}

#rev_slider_18_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_18_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(40, 40, 40, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_18_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(40, 40, 40, 1);
    opacity: 1
}

#rev_slider_18_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(40, 40, 40, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_18_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(40, 40, 40, 1)
}

/*Home 20*/

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}
.tp-static-layers .tp-caption ul {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important
}

.tp-static-layers .tp-caption ul a {
    display: block;
    padding: 5px 50px !important;
    transition: background .3s !important
}

.tp-static-layers .tp-caption ul a:hover {
    background-color: #3A3774;
    color: #fff
}

.tp-static-layers .tp-caption h3 {
    margin: 0;
    color: #fff;
    padding-left: 50px;
    font-weight: bold;
    font-size: 18px;
    padding-top: 50px;
    padding-bottom: 20px
}

#rev_slider_15_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_15_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(40, 40, 40, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_15_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(40, 40, 40, 1);
    opacity: 1
}

#rev_slider_15_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(40, 40, 40, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_15_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(40, 40, 40, 1)
}

/*Home 19*/

.tp-caption.LaBtnOutlineBlack,
.LaBtnOutlineBlack {
    color: #282828;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    border-color: #282828;
    border-style: solid;
    border-width: 1 1 1 1px;
    border-radius: 0px 0px 0px 0px
}

.tp-caption.LaBtnOutlineBlack:hover,
.LaBtnOutlineBlack:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #282828;
    border-color: #282828;
    border-style: solid;
    border-width: 2 2 2 2px;
    border-radius: 0px 0px 0px 0px;
    cursor: pointer
}

.tp-parallax-wrap.rev-group-50,
.tp-parallax-wrap.rev-group-50 > .tp-loop-wrap,
.tp-parallax-wrap.rev-group-50 > .tp-loop-wrap > .tp-mask-wrap {
    height: 100%;
    width: 100%
}
#rev_slider_14_1 .larev-dot2.tp-bullets .tp-bullet {
    width: 60px;
    padding-bottom: 15px;
    background: none
}

#rev_slider_14_1 .larev-dot2.tp-bullets .tp-bullet .tp-bullet-title {
    color: rgba(40, 40, 40, 0.5);
    font-size: 14px;
    opacity: 0;
    position: relative;
    top: -7px
}

#rev_slider_14_1 .larev-dot2.tp-bullets .tp-bullet.selected .tp-bullet-title {
    color: rgba(40, 40, 40, 1);
    opacity: 1
}

#rev_slider_14_1 .larev-dot2.tp-bullets .tp-bullet-wrap {
    position: absolute;
    bottom: 0;
    width: 30px;
    border-bottom: 2px solid rgba(40, 40, 40, 0.5);
    left: 0;
    transition: all .3s
}

#rev_slider_14_1 .larev-dot2.tp-bullets .selected .tp-bullet-wrap {
    width: 60px;
    border-bottom-color: rgba(40, 40, 40, 1)
}

/*Home 6 Custom Css*/
@media (max-width: 61.94em) {
  .home-6-rev-text {
    text-align: center;
    font-size: 30px !important;
    line-height: 1.3 !important; } }

@media (max-width: 61.94em) {
  .home-6-rev-text .light {
    display: block;
    text-align: center; } }

@media (max-width: 61.94em) {
  .home-6-rev-shape {
    opacity: 0 !important;
    visibility: hidden !important; } }